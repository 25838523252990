import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-52e34dca"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "app-versions"
}
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["NLayoutSider"], {
    class: _normalizeClass(["main-navigation", {visible: $setup.showSider}]),
    id: "sidebar",
    bordered: "",
    collapsed: $setup.isCollapsed,
    "show-trigger": $setup.showSider,
    "collapse-mode": "width",
    "collapsed-width": 64,
    width: 230,
    "native-scrollbar": false,
    "onUpdate:collapsed": $setup.onSidebarTriggered,
    style: _normalizeStyle($setup.containerHeightOffset)
  }, {
    default: _withCtx(() => [
      _createVNode($setup["NMenu"], {
        "collapsed-width": 64,
        "icon-size": 14,
        "collapsed-icon-size": 22,
        "expand-icon": () => undefined,
        options: $setup.menuOptions,
        ref: "sideMenuRef",
        value: $setup.selectedKey,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (($setup.selectedKey) = $event)),
        accordion: ""
      }, null, 8, ["options", "value"]),
      ($setup.clientVersion && $setup.releaseDate && !$setup.isCollapsed)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", null, _toDisplayString($setup.clientVersionMessage), 1),
            ($setup.apiVersion)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString($setup.apiVersionMessage), 1))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["collapsed", "show-trigger", "class", "style"]))
}